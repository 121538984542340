import React from 'react';

export const ARROW_RIGHT = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14.5703L13.0711 8.49925M13.0711 8.49925L7 2.42818M13.0711 8.49925L2 8.49924"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);
