import React from 'react';

import { Ruler } from '../Ruler';

export const Heading = ({
  ruler = true,
  supTitle = null,
  title = null,
  subTitle = null,
}) => {
  return (
    <div className="heading">
      {ruler ? <Ruler /> : null}

      {supTitle ? <div className="heading__sup-title">{supTitle}</div> : null}

      {title ? <div className="heading__title">{title}</div> : null}

      {subTitle ? <div className="heading__sub-title">{subTitle}</div> : null}
    </div>
  );
};
