import React from 'react';

export const ARROW_BOTTOM = (
  <svg
    width="13"
    height="9"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.21875L6.369 6.58775L11.738 1.21875"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);
