import React from 'react';

export const LOCATION = (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.83203C0 3.51832 2.68629 0.832031 6 0.832031C9.31371 0.832031 12 3.51832 12 6.83203C12 8.84963 10.7935 10.5599 9.4033 11.7647L7.10902 13.7531C6.47255 14.3047 5.52745 14.3047 4.89098 13.7531L2.5967 11.7647C1.20651 10.5599 0 8.84963 0 6.83203ZM6 8.16536C6.73638 8.16536 7.33333 7.56841 7.33333 6.83203C7.33333 6.09565 6.73638 5.4987 6 5.4987C5.26362 5.4987 4.66667 6.09565 4.66667 6.83203C4.66667 7.56841 5.26362 8.16536 6 8.16536Z"
      fill="white"
    />
  </svg>
);
