import React from 'react';
import { PrismicRichText } from '@prismicio/react';

import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { MaxWidth } from '../../components/MaxWidth';

import { generateImageProps, isImage } from '../../helpers/image';
import { isLink } from '../../helpers/is-link';
import classNames from 'classnames';

const ChooseBatman = ({ slice }) => {
  const Icon = ({ icon }) => {
    switch (icon) {
      case 'equipe':
        return (
          <img
            src="/icons/choose-batman/equipe.svg"
            width="36"
            height="36"
            alt=""
          />
        );

      case 'horloge':
        return (
          <img
            src="/icons/choose-batman/horloge.svg"
            width="36"
            height="36"
            alt=""
          />
        );

      case 'chauve-souris':
        return (
          <img
            src="/icons/choose-batman/chauve-souris.svg"
            width="36"
            height="36"
            alt=""
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames('choose-batman', {
        'choose-batman--no-image': !isImage(slice.primary.image),
      })}
    >
      <div className="choose-batman__container">
        <MaxWidth>
          <div className="choose-batman__title">
            <Title titleUpperCase align="center">
              <PrismicRichText field={slice.primary.title} />
            </Title>
          </div>

          <div className="choose-batman__subtitle">
            <PrismicRichText field={slice.primary.subTitle} />
          </div>

          <div className="choose-batman__content">
            <div className="choose-batman__content__item">
              <div className="choose-batman__content__item__text">
                <PrismicRichText field={slice.primary.text} />
              </div>

              <div className="choose-batman__content__item__buttons">
                {isLink(slice.primary.buttonLink) ? (
                  <Button field={slice.primary.buttonLink}>
                    <PrismicRichText field={slice.primary.buttonText} />
                  </Button>
                ) : null}

                {isLink(slice.primary.buttonLink2) ? (
                  <Button
                    theme="border-black"
                    field={slice.primary.buttonLink2}
                  >
                    <PrismicRichText field={slice.primary.secButtonText} />
                  </Button>
                ) : null}
              </div>

              <div className="choose-batman__content__item__list">
                {slice.items.map((item, index) => (
                  <div
                    className="choose-batman__content__item__list__item"
                    key={index}
                  >
                    <div className="choose-batman__content__item__list__item__block">
                      <div className="choose-batman__content__item__list__item__icon">
                        <Icon icon={item.icon} />
                      </div>
                      <div className="choose-batman__content__item__list__item__title">
                        <PrismicRichText field={item.titreIcon} />
                      </div>
                    </div>
                    <div className="choose-batman__content__item__list__item__text">
                      <PrismicRichText field={item.iconText} />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {isImage(slice.primary.image) ? (
              <div className="choose-batman__content__item">
                <div className="choose-batman__content__item__image">
                  <img
                    {...generateImageProps(slice.primary.image, {
                      width: 1200,
                      sizes: '600px',
                    })}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </MaxWidth>
      </div>
    </div>
  );
};

export default ChooseBatman;
